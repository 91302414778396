import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';

// project-import
import { ThemeMode } from 'config';

import logoDark from 'assets/images/ym-logo.webp';
import logo from 'assets/images/ym-logo.webp';

// ==============================|| LOGO SVG ||============================== //

const LogoMain = () => {
  const theme = useTheme();
  return (
    <img src={theme.palette.mode === ThemeMode.DARK ? logoDark : logo} alt="Yieldmo" width="60" />
  );
};

LogoMain.propTypes = {
  reverse: PropTypes.bool
};

export default LogoMain;
