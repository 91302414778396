import { API_URL } from "../../constants";

export const ApiConfig = {
    test: `${API_URL}/test-val`,
    brand: `${API_URL}/google/brand`,
    brand_details: `${API_URL}/an/brand`,
    brand_colors : `${API_URL}/brand-colors`,
    product: `${API_URL}/google/product`,
    caption: `${API_URL}/replicate/caption`,
    caption_v2: `${API_URL}/google/caption`,
    iab_category: `${API_URL}/google/iab-category`,
    iab_subcategory: `${API_URL}/google/iab-subcategory`,
    perf_data_by_category: `${API_URL}/perf-data-by-category`,
    top_segments_by_category: `${API_URL}/top-segments-by-category`,
    top_domains_by_category: `${API_URL}/top-domains-by-category`,
    top_formats_by_category: `${API_URL}/top-formats-by-category`,
    keywords: `${API_URL}/google/keywords`,
    tagline: `${API_URL}/google/tagline`,
    ctas: `${API_URL}/google/ctas`,
    vision: `${API_URL}/google/vision`,
    labels: `${API_URL}/google/label-description`,
    text: `${API_URL}/google/text-description`,
    logo_description: `${API_URL}/google/logo-description`,
    segments: `${API_URL}/top-segment-expanded`,
    save_image: `${API_URL}/save-image`,
    looker_question: `${API_URL}/looker_question`,
    publisher: `${API_URL}/publisher`,
    advertiser: `${API_URL}/advertiser`,
    campaign: `${API_URL}/campaign`,
    adbuilder_segments: `${API_URL}/top-and-bottom-segments-from-adbuilder`,
    adbuilder_html: `${API_URL}/adbuilder-html`,
};
