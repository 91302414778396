import { useMemo, useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Alert, AppBar, Toolbar, useMediaQuery } from '@mui/material';

// project import
import AppBarStyled from './AppBarStyled';
import HeaderContent from './HeaderContent';
import IconButton from 'components/@extended/IconButton';

import { MenuOrientation, ThemeMode } from 'config';
import useConfig from 'hooks/useConfig';
import { dispatch, useSelector } from 'store';
import { openDrawer } from 'store/reducers/menu';

// assets
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

import { testPing } from 'services';

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

const Header = () => {
  const [vpnMessage, setVpnMessage] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null); // Added state for timeout ID
  const theme = useTheme();
  const downLG = useMediaQuery(theme.breakpoints.down('lg'));
  const { menuOrientation } = useConfig();

  const menu = useSelector((state) => state.menu);
  const { drawerOpen } = menu;

  const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !downLG;

  // header content
  const headerContent = useMemo(() => <HeaderContent />, []);

  const iconBackColorOpen = theme.palette.mode === ThemeMode.DARK ? 'grey.200' : 'grey.300';
  const iconBackColor = theme.palette.mode === ThemeMode.DARK ? 'background.default' : 'grey.100';

  useEffect(() => {
    const fetchData = async () => {
      let id; // Separate variable to capture timeoutId

      try {
        await Promise.race([
          testPing(),
          new Promise((_, reject) => {
            id = setTimeout(() => {
              clearTimeout(id);
              reject(new Error('Request timed out'));
            }, 3000); // Set timeout to 3 seconds (3000 milliseconds)
            setTimeoutId(id); // Store the timeout ID in state
          }),
        ]);

        clearTimeout(timeoutId); // Clear the timeout if the request completes before the timeout
        setVpnMessage(null); // Clear any previous error message
        // Process the response
      } catch (error) {
        clearTimeout(id); // Clear the timeout in case of error
        setVpnMessage(
          <Alert severity="error">
            Could not connect to the API. Make sure you are on VPN. {error.message}
          </Alert>
        );
      }
    };

    const intervalId = setInterval(fetchData, 10000); // Poll every 10 seconds (10000 milliseconds)

    return () => {
      clearInterval(intervalId); // Clear the interval when the component unmounts or when the effect re-runs
      clearTimeout(timeoutId); // Clear the timeout when the component unmounts or when the effect re-runs
    };
  }, []);


  // common header
  const mainHeader = (
    <Toolbar>
      {!isHorizontal ? (
        <IconButton
          aria-label="open drawer"
          onClick={() => dispatch(openDrawer(!drawerOpen))}
          edge="start"
          color="secondary"
          variant="light"
          sx={{ color: 'text.primary', bgcolor: drawerOpen ? iconBackColorOpen : iconBackColor, ml: { xs: 0, lg: -2 } }}
        >
          {!drawerOpen ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </IconButton>
      ) : null}
      {vpnMessage}
      {headerContent}
    </Toolbar>
  );

  // app-bar params
  const appBar = {
    position: 'fixed',
    color: 'inherit',
    elevation: 0,
    sx: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      zIndex: 1200,
      width: isHorizontal ? '100%' : drawerOpen ? 'calc(100% - 260px)' : { xs: '100%', lg: 'calc(100% - 60px)' }
      // boxShadow: theme.customShadows.z1
    }
  };

  return (
    <>
      {!downLG ? (
        <AppBarStyled open={drawerOpen} {...appBar}>
          {mainHeader}
        </AppBarStyled>
      ) : (
        <AppBar {...appBar}>{mainHeader}</AppBar>
      )}
    </>
  );
};

export default Header;
