// material-ui
import { useTheme } from '@mui/material/styles';

import logoIconDark from 'assets/images/ym-logo.webp';
import logoIcon from 'assets/images/ym-logo.webp';
import { ThemeMode } from 'config';

// ==============================|| LOGO ICON SVG ||============================== //

const LogoIcon = () => {
  const theme = useTheme();

  return (
    <img src={theme.palette.mode === ThemeMode.DARK ? logoIconDark : logoIcon} alt="Mantis" width="50" />
  );
};

export default LogoIcon;
