// third-party
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
  openItem: ['dashboard'],
  openComponent: 'buttons',
  selectedID: null,
  drawerOpen: false,
  componentDrawerOpen: true,
  menu: {},
  error: null
};

// ==============================|| SLICE - MENU ||============================== //

export const fetchMenu = createAsyncThunk('', async () => {
  return {
    "dashboard": {
        "id": "group-dashboard",
        "title": "dashboard",
        "type": "group",
        "icon": "dashboard",
        "children": [
            {
                "id": "ymax-ai",
                "title": "ymax-ai",
                "type": "collapse",
                "icon": "dashboard",
                "children": [
                    {
                        "id": "target",
                        "title": "target",
                        "type": "item",
                        "url": "/dashboard/target",
                        "breadcrumbs": false
                    },
                    // {
                    //     "id": "seabaggpt",
                    //     "title": "seabaggpt",
                    //     "type": "item",
                    //     "url": "/dashboard/seabagGPT",
                    //     "breadcrumbs": false
                    // }
                ]
            }
        ]
    }
};
});

const menu = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    activeItem(state, action) {
      state.openItem = action.payload.openItem;
    },

    activeID(state, action) {
      state.selectedID = action.payload;
    },

    activeComponent(state, action) {
      state.openComponent = action.payload.openComponent;
    },

    openDrawer(state, action) {
      state.drawerOpen = action.payload;
    },

    openComponentDrawer(state, action) {
      state.componentDrawerOpen = action.payload.componentDrawerOpen;
    },

    hasError(state, action) {
      state.error = action.payload;
    }
  },

  extraReducers(builder) {
    builder.addCase(fetchMenu.fulfilled, (state, action) => {
      state.menu = action.payload.dashboard;
    });
  }
});

export default menu.reducer;

export const { activeItem, activeComponent, openDrawer, openComponentDrawer, activeID } = menu.actions;
