// material-ui
import { Button, Link, CardMedia, Stack, Typography } from '@mui/material';

// project import
import MainCard from 'components/MainCard';

// assets
import avatar from 'assets/images/users/avatar-group.png';
import AnimateButton from 'components/@extended/AnimateButton';

// ==============================|| DRAWER CONTENT - NAVIGATION CARD ||============================== //

const FeedbackCard = () => (
  <MainCard sx={{ bgcolor: 'grey.50', m: 3 }}>
    <Stack alignItems="center" spacing={2.5}>
      <CardMedia component="img" image={avatar} />
      <Stack alignItems="center">
        <Typography variant="h5">We want to know.</Typography>
        <Typography variant="h6" color="secondary">
         Have feedback, questions
        </Typography>
        <Typography variant="h6" color="secondary">
         or ideas? Send us a note.
        </Typography>
      </Stack>
      <AnimateButton>
        <Button variant="shadow" size="small" component={Link} href="https://forms.gle/DM1jDJnZuLGvkWXn9" target="_blank">
            Send Feedback 
        </Button>
      </AnimateButton>
    </Stack>
    <Stack alignItems="center" padding={10}>
      <Stack alignItems="center">
        <Typography variant="h4">Connected?</Typography>
        <Typography variant="h6" color="secondary">
            Make sure you are
        </Typography>
        <Typography variant="h6" color="secondary">
            logged onto the VPN! 
        </Typography>
      </Stack>
    </Stack>
  </MainCard>
);

export default FeedbackCard;
