import { ApiConfig } from "./config";

const handleRequest = async (url, method = 'GET', body) => {
    try {
        const response = await fetch(url, { method, body });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching user count:', error);
        throw error;
    }
}

const handleImageRequest = async (url, file) => {
    const formData = new FormData();
    formData.append('file', file);
    try {
        const response = await fetch(url, { method: 'POST', body: formData });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching user count:', error);
        throw error;
    }
}

// todo: can get rid of; just used for testing
export const fetchUserCount = async () => {
    return await handleRequest(ApiConfig.test);
};

export const fetchBrand = async (caption, image_text) => {
    const queryParams = new URLSearchParams({
        caption,
        image_text,
    });
    const url = `${ApiConfig.brand}?${queryParams}`;
    return await handleRequest(url);
};

export const fetchPredictedBrand = async (caption, image_text) => {
    const queryParams = new URLSearchParams({
        caption,
        image_text,
    });
    const url = `${ApiConfig.brand}?${queryParams}`;
    return await handleRequest(url);
};

export const fetchBrandDetails = async (name) => {
    const queryParams = new URLSearchParams({
        name
    });
    const url = `${ApiConfig.brand_details}?${queryParams}`;
    return await handleRequest(url);
};

export const fetchBrandColors = async (file) => {
    const url = `${ApiConfig.brand_colors}`;
    return await handleImageRequest(url, file);
};

export const fetchProduct = async (caption, image_text, predicted_brand) => {
    const queryParams = new URLSearchParams({
        caption,
        image_text,
        predicted_brand,
    });
    const url = `${ApiConfig.product}?${queryParams}`;
    return await handleRequest(url);
};

export const fetchIabCategory = async (caption, image_text, predicted_brand, product) => {
    const queryParams = new URLSearchParams({
        caption,
        image_text,
        predicted_brand,
        product,
    });
    const url = `${ApiConfig.iab_category}?${queryParams}`;
    return await handleRequest(url);
};

export const fetchIabSubCategory = async (product, iab_category) => {
    const queryParams = new URLSearchParams({
        product, iab_category
    });
    const url = `${ApiConfig.iab_subcategory}?${queryParams}`;
    return await handleRequest(url);
};


export const fetchCaption = async (file) => {
    const url = `${ApiConfig.caption}`;
    return await handleImageRequest(url, file);
};

export const fetchCaptionV2 = async (file) => {
    const url = `${ApiConfig.caption_v2}`;
    return await handleImageRequest(url, file);
};

export const fetchPerfData = async (verticals, days = 7) => {
    const queryParams = new URLSearchParams({
        verticals, days
    });
    const url = `${ApiConfig.perf_data_by_category}?${queryParams}`;
    return await handleRequest(url);
};

export const fetchTopDomains = async (verticals, days = 7, top_n = 10) => {
    const queryParams = new URLSearchParams({
        verticals, days, top_n
    });
    const url = `${ApiConfig.top_domains_by_category}?${queryParams}`;
    return await handleRequest(url);
};

export const fetchTopFormats = async (verticals, days = 7) => {
    const queryParams = new URLSearchParams({
        verticals, days
    });
    const url = `${ApiConfig.top_formats_by_category}?${queryParams}`;
    return await handleRequest(url);
};

export const fetchTopSegments = async (verticals, days = 7) => {
    const queryParams = new URLSearchParams({
        verticals, days
    });
    const url = `${ApiConfig.top_segments_by_category}?${queryParams}`;
    return await handleRequest(url);
};

export const fetchVision = async (file) => {
    const url = `${ApiConfig.vision}`;
    return await handleImageRequest(url, file);
};

export const fetchLabels = async (file) => {
    const url = `${ApiConfig.labels}`;
    return await handleImageRequest(url, file);
};

export const fetchText = async (file) => {
    const url = `${ApiConfig.text}`;
    return await handleImageRequest(url, file);
};

export const fetchLogoDescription = async (file) => {
    const url = `${ApiConfig.logo_description}`;
    return await handleImageRequest(url, file);
};

export const fetchKeywords = async (demographic_segments, product, predicted_brand) => {
    const data = {
        demographic_segments, product, predicted_brand
    };
    console.log(data);
    const url = `${ApiConfig.keywords}`;
    return await handleRequest(url, 'POST', JSON.stringify(data));
};

export const fetchTaglines = async (demographic_segments, product, predicted_brand, image_text) => {
    const data = {
        demographic_segments, product, predicted_brand, image_text
    };
    console.log(data);
    const url = `${ApiConfig.taglines}`;
    return await handleRequest(url, 'POST', JSON.stringify(data));
};

export const fetchTopSegmentsExpanded = async (product, brand, image_text, iab_category, iab_subcategory) => {
    const queryParams = new URLSearchParams({
        product, brand, image_text, iab_category, iab_subcategory
    });
    const url = `${ApiConfig.segments}?${queryParams}`;
    return await handleRequest(url);
};

export const fetchCtas = async (product, predicted_brand, image_text, top_n = 5) => {
    const queryParams = new URLSearchParams({
        product, predicted_brand, image_text, top_n
    });
    const url = `${ApiConfig.ctas}?${queryParams}`;
    return await handleRequest(url);
};

export const saveImage = async (file) => {
    const url = `${ApiConfig.save_image}`;
    return await handleImageRequest(url, file);
};

export const testPing = async () => {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 3000); // Set timeout to 3 seconds (3000 milliseconds)
  
    try {
      const response = await fetch(ApiConfig.test, { signal: controller.signal });
      clearTimeout(timeoutId); // Clear the timeout if the request completes before the timeout
      return await response.json();
    } catch (error) {
      if (error.name === "AbortError") {
        throw new Error("Request timed out"); // Throw a custom error message for timeout
      } else {
        throw error; // Rethrow the original error for other errors
      }
    }
  };

export const fetchLookerGptQuestionAnswer = async (question, advertiser, publisher, campaign) => {
    let requestBody = {
        question: question
    };

    // Include advertiser if it is not null
    if (advertiser !== null) {
        requestBody.advertiser = advertiser;
    }

    // Include publisher if it is not null
    if (publisher !== null) {
        requestBody.publisher = publisher;
    }

    if (campaign !== null) {
        requestBody.campaign = campaign;
    }

    const queryParams = new URLSearchParams(requestBody);
    const url = `${ApiConfig.looker_question}?${queryParams}`;
    return await handleRequest(url);
}

export const fetchAdvertiser = async () => {
    const url = `${ApiConfig.advertiser}`;
    return await handleRequest(url);
}

export const fetchPublisher = async () => {
    const url = `${ApiConfig.publisher}`;
    return await handleRequest(url);
}

export const fetchCampaign = async () => {
    const url = `${ApiConfig.campaign}`;
    return await handleRequest(url);
}

export const fetchAdbuilderSegments = async (adbuilder_url) => {
    const queryParams = new URLSearchParams({
        adbuilder_url
    });
    const url = `${ApiConfig.adbuilder_segments}?${queryParams}`;
    return await handleRequest(url);
}

export const fetchAdbuilderHTML = async (adbuilder_url) => {
    const queryParams = new URLSearchParams({
        adbuilder_url
    });
    const url = `${ApiConfig.adbuilder_html}?${queryParams}`;
    return await handleRequest(url);
}
